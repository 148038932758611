import ApplicationController from './application-controller'

export default class TabsController extends ApplicationController {
  static targets = ['panels', 'tabs']

  connect () {
    this.activateByPanel({ panel: this.panelsTargets.find(el => el.classList.contains('is-active')) })
    // Show panel referenced in the address bar
    this.handleDocumentLocation()
    // Fallback if there is no active tab/panel
    this.activateFirstMaybe()
    // this used when the outside element is linking to the panel
    window.addEventListener('hashchange', this.handleDocumentLocation.bind(this), { capture: true })
  }

  activateFirstMaybe () {
    if (this.activePanel) return
    if (this.tabsTargets.length < 1 && this.panelsTargets.length < 1) return

    if (this.activeTab) {
      this.activateByIdSelector(this.activeTab.dataset.activates)
    } else if (this.tabsTargets[0]) {
      this.activateByIdSelector(this.tabsTargets[0].dataset.activates)
    } else {
      this.activateByPanel({ panel: this.panelsTargets[0] })
    }
  }

  handleDocumentLocation (_ev) {
    if (location.hash.length <= 1) return

    this.activateByIdSelector(location.hash)
  }

  // Handles clicks on the tabs
  handleTabClick (ev) {
    ev.preventDefault()
    this.activateByIdSelector(ev.currentTarget.dataset.activates)
    this.pushPanelToHistory()
  }

  handlePreviousClick () {
    if (undefined === this.activeIndex) return
    this.activateByIndex(this.activeIndex - 1)
  }

  handleNextClick () {
    if (undefined === this.activeIndex) return
    this.activateByIndex(this.activeIndex + 1)
  }

  activateByIndex (index) {
    const lastIndex = this.panelsTargets.length - 1
    if (index > lastIndex) {
      index = 0
    } else if (index < 0) {
      index = lastIndex
    }

    const panel = this.panelsTargets[index]

    this.activateByPanel({ panel, index })
  }

  pushPanelToHistory () {
    if (!this.activePanel || this.activePanel.id.length < 1) return

    history.pushState(null, null, `#${this.activePanel.id}`)
  }

  activateByIdSelector (selector) {
    this.activatePanelById(selector.substr(1))
  }

  activateByPanel ({ panel, index = null }) {
    if (!panel) return

    this.activateTabByIdSelector(`#${panel.id}`)
    this.hideActivePanel()
    panel.classList.add('is-active')
    this.previouslyActivePanel = this.activePanel
    this.activePanel = panel
    this.activeIndex = index || this.panelsTargets.findIndex(other => other === panel)
  }

  hideActivePanel () {
    this.activePanel?.classList.remove('is-active')
  }

  activateTabByIdSelector (idSelector) {
    const tab = this.tabsTargets.find(el => el.dataset.activates === idSelector)
    tab && this.activateTab(tab)
  }

  activateTab (tab) {
    if (!tab) return

    this.hideActiveTab()
    tab.classList.add('is-active')
    this.activeTab = tab
  }

  hideActiveTab () {
    this.activeTab?.classList.remove('is-active')
  }

  activatePanelById (id) {
    const index = this.panelsTargets.findIndex(panel => panel.id === id)
    if (index < 0) return

    const panel = this.panelsTargets[index]
    this.activateByPanel({ panel, index })
  }
}
