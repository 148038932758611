import laxLib from 'lax.js/lib/lax.js'

if (globalThis.lax === undefined) globalThis.lax = laxLib

const { lax } = globalThis

let currentOffset = 0

lax.addDriver('scrollY', () => {
  return globalThis.scrollY + currentOffset
}, {
  inertiaEnabled: true,
  frameStep: 2
})

export function idempotentLaxInit () {
  if (lax._myInitialized) return

  lax._myInitialized = true
  lax.init()
}

function drainOffset ({ speed }) {
  let offset
  if (currentOffset < 0) {
    offset = currentOffset + speed
    if (offset > 0) {
      currentOffset = 0
    } else {
      currentOffset = offset
    }
  } else if (currentOffset > 0) {
    offset = currentOffset - speed

    if (offset < 0) {
      currentOffset = 0
    } else {
      currentOffset = offset
    }
  }

  currentOffset !== 0 && globalThis.setTimeout(drainOffset, 33, { speed })
}

export function scrollIn ({ speed, offset }) {
  currentOffset = offset
  globalThis.setTimeout(drainOffset, 33, { speed })
}
export default lax
