import ApplicationController from 'controllers/application-controller'

export default class ModalVideoController extends ApplicationController {
  static targets = ['video', 'overlay']

  constructor (context) {
    super(context)
    this.stopAndClose = this.stopAndClose.bind(this)
  }

  openAndPlay () {
    document.firstElementChild.classList.add('video-modal-open')
    this.videoTarget.addEventListener('ended', this.stopAndClose)
    this.videoTarget.play()
  }

  stopWithOverlayClick (ev) {
    if (ev.target !== this.overlayTarget) return

    this.stopAndClose()
  }

  stopAndClose () {
    this.videoTarget.removeEventListener('ended', this.stopAndClose)
    this.videoTarget.pause()
    document.firstElementChild.classList.remove('video-modal-open')
  }
}
