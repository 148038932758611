export default class MediaQuery {
  constructor ({ name, dimensions }) {
    Object.assign(this, { name, dimensions })
    this.mediaQueryList = globalThis.matchMedia(this.toQueryString())
    this.mediaQueryList.addEventListener('change', this.#handleChange.bind(this))
    this.klass = this.constructor
    this.listeners = { match: [] }
  }

  #handleChange (ev) {
    const { matches } = ev
    matches && this.#callListeners('match', this)
  }

  toQueryString () {
    let string = ''
    if (this.dimensions.min !== undefined) {
      string += `(min-width: ${this.dimensions.min}em)`
      if (this.dimensions.max) string += ' and '
    }

    if (this.dimensions.max !== undefined) {
      string += `(max-width: ${this.dimensions.max}em)`
    }

    return string
  }

  /**
  * @param {type} type - allowed values: change, match
  * @param {function} callback
  */
  addListener (type, callback) {
    this.listeners[type].push(callback)
    this.matches && this.#callListeners('match', this)
  }

  #callListeners (type, data) {
    this.listeners[type].forEach(listener => {
      listener(data)
    })
  }

  fits (other) {
    return this.klass.names.indexOf(other) <= this.klass.names.indexOf(this.name)
  }

  unfits (other) {
    return !this.fits(other)
  }

  get minPx () {
    if (this.dimensions.min === undefined) return 0

    return this.constructor.emToPx(this.dimensions.min)
  }

  get maxPx () {
    if (this.dimensions.max === undefined) return Number.MAX_SAFE_INTEGER

    return this.constructor.emToPx(this.dimensions.max)
  }

  get matches () {
    return this.mediaQueryList.matches
  }

  get includes () {
    const i = this.klass.names.indexOf(this.name)
    return i >= 0 ? this.klass.names.slice(0, i + 1) : []
  }

  static definitions = {
    small: { min: 0, max: 48 },
    medium: { min: 48, max: 64 },
    large: { min: 64, max: 80 },
    xlarge: { min: 80, max: 90 },
    xxlarge: { min: 90 }
  }

  static names = []
  static mediaQueries = []
  static byName = {}
  static listeners = { change: [] }

  static init () {
    if (this.initialized) return

    this.names = Object.keys(this.definitions)
    this.names.forEach(name => {
      const mediaQuery =
        new MediaQuery({ name, dimensions: this.definitions[name] })
      this.mediaQueries.push(mediaQuery)
      this.byName[name] = mediaQuery
      mediaQuery.addListener('match', matched => {
        this.current = matched
      })
    })

    this.initialized = true
  }

  static get current () {
    return this._current
  }

  static set current (value) {
    this._current = value

    this.callListeners('change', value)

    return this._current
  }

  /**
  * @param {type} type - only 'change' is allowed
  * @param {function} callback
  */
  static addListener (type, callback) {
    this.listeners[type].push(callback)

    if (this.current) {
      this.callListeners('change', this.current)
    }
  }

  static removeListener (type, callback) {
    this.listeners[type].splice(this.listeners[type].indexOf(callback), 1)
  }

  static callListeners (type, data) {
    this.listeners[type].forEach(listener => {
      listener(data)
    })
  }

  static emToPx (value) {
    return this.sizeOfEm * value
  }

  static get sizeOfEm () {
    return parseFloat(getComputedStyle(globalThis.document.body).fontSize)
  }
}

MediaQuery.init()
