import TabsController from 'controllers/tabs-controller'
import MediaQuery from 'lib/media-query'
import observer from 'lib/lozad-observer'
import lax, { idempotentLaxInit, scrollIn } from 'lib/wrapped-lax'
import { useIntersection } from 'stimulus-use'

const { small, medium, large, xlarge, xxlarge } = MediaQuery.byName

export default class HowItWorksTabsController extends TabsController {
  connect () {
    super.connect()
    useIntersection(this)

    this.preloadedPanels = []
  }

  appear () {
    this.visible = true
    this.handleVisible()
  }

  disappear () {
    this.visible = false
    this.deactivateAnimations(this.activePanel)
  }

  activateByPanel (args) {
    super.activateByPanel(args)
    if (this.visible) {
      this.handleVisible()
      scrollIn({ speed: 10, offset: window.innerHeight / 2 * -1 })
    }
  }

  handleVisible () {
    this.preloadPanelImages()
    this.deactivateAnimations()
    this.initAnimations()
  }

  preloadPanelImages () {
    if (this.preloadedPanels.includes(this.activePanel)) return

    this.preloadedPanels.push(this.activePanel)
    this.activePanel.querySelectorAll('.lazy:not([data-loaded])').forEach(element => observer.triggerLoad(element))
  }

  initLax () {
    idempotentLaxInit()
  }

  initBlink () {
    if (this.blinkingTab && this.blinkingTab !== this.nextTab) {
      this.blinkingTab.classList.remove('blink')
    }

    if (!this.nextTab) return

    this.blinkingTab = this.nextTab
    this.nextTab?.classList.add('blink')
  }

  get nextTab () {
    return this.tabsTargets[this.activeIndex + 1]
  }

  initAnimations () {
    this.initLax()
    lax.running || lax.start()
    this.initBlink()
    const panelId = this.activePanel.id
    const animations = this.animations[panelId]
    if (!animations) return

    // lax.addElement(this.tabsTargets[0], { millis: { blur: [[0, 500, 1000], [0, 10, 0]] } })

    Object.keys(animations).forEach(name => {
      const element = this.activePanel.querySelectorAll(`.${name}`)[0]
      element && lax.addElement(element, { scrollY: animations[name] })
    })
  }

  deactivateAnimations (panel = this.previouslyActivePanel) {
    lax.stop()

    if (!panel) return

    const animations = this.animations[this.activePanel.id]

    Object.keys(animations).forEach(name => {
      const element = panel.querySelectorAll(`.${name}`)[0]
      element && lax.removeElement(element)
    })
    // DIRTY FIX:
    lax.elements = []
  }

  get animations () {
    return {
      'step-1': {
        woman: {
          translateX: [
            this.constructor.inOutMap(250),
            {
              [small.maxPx]: [0, 80, 100, 90, 70],
              [medium.maxPx]: [50, 130, 150, 140, 120],
              [large.maxPx]: [80, 120, 140, 130, 120],
              [xlarge.maxPx]: [100, 180, 200, 180, 160],
              [xxlarge.maxPx]: [140, 180, 200, 190, 170]
            }
          ],
          scale: [
            this.constructor.inMap(300),
            {
              [small.maxPx]: [0.7, 0.9, 1],
              [medium.maxPx]: [0.8, 0.9, 1],
              [xlarge.maxPx]: [0.9, 0.9, 1]
            }
          ]
        },
        man: {
          translateX: [
            this.constructor.inOutMap(250),
            {
              [small.maxPx]: [0, -60, -70, -60, -50],
              [medium.maxPx]: [-40, -150, -170, -160, -150],
              [large.maxPx]: [0, -70, -80, -75, -60],
              [xlarge.maxPx]: [-65, -160, -150, -130, -120],
              [xxlarge.maxPx]: [-90, -125, -140, -130, -100]
            }
          ],
          scale: [
            this.constructor.inMap(300),
            {
              [xlarge.maxPx]: [0.7, 0.8, 1],
              [xxlarge.maxPx]: [0.8, 0.95, 1]
            }
          ]
        },
        button: {
          translateX: [
            this.constructor.inOutMap(),
            {
              [small.maxPx]: [0, -90, -100, -80, -70],
              [medium.maxPx]: [0, -90, -100, -80, -70],
              [large.maxPx]: [0, -90, -100, -80, -70],
              [xlarge.maxPx]: [0, -120, -180, -170, -160]
            }
          ],
          scale: [
            this.constructor.inOutMap(20),
            [0.4, 1, 1.5, 1, 1]
          ],
          'hue-rotate': [
            this.constructor.inOutMap(20),
            [0.0, 0, 90, 0, 0]
          ]
        }
      },
      'step-2': {
        man: {
          translateX: [
            this.constructor.inOutMap(250),
            {
              [small.maxPx]: [0, 80, 100, 90, 70],
              [medium.maxPx]: [50, 130, 150, 140, 120],
              [large.maxPx]: [80, 120, 140, 130, 120],
              [xlarge.maxPx]: [100, 180, 200, 180, 160],
              [xxlarge.maxPx]: [140, 180, 200, 190, 170]
            }
          ],
          scale: [
            this.constructor.inMap(300),
            {
              [small.maxPx]: [0.7, 0.9, 1],
              [medium.maxPx]: [0.8, 0.9, 1],
              [xlarge.maxPx]: [0.9, 0.9, 1]
            }
          ]
        },
        woman: {
          translateX: [
            this.constructor.inOutMap(250),
            {
              [small.maxPx]: [0, -60, -70, -60, -50],
              [medium.maxPx]: [-40, -150, -170, -160, -150],
              [large.maxPx]: [0, -70, -80, -75, -60],
              [xlarge.maxPx]: [-65, -160, -150, -130, -120],
              [xxlarge.maxPx]: [-90, -125, -140, -130, -100]
            }
          ],
          scale: [
            this.constructor.inMap(300),
            {
              [xlarge.maxPx]: [0.7, 0.8, 1],
              [xxlarge.maxPx]: [0.8, 0.95, 1]
            }
          ]
        }
      },
      'step-3': {
        woman: {
          translateX: [
            this.constructor.inOutMap(250),
            {
              [small.maxPx]: [0, 80, 100, 90, 70],
              [medium.maxPx]: [50, 130, 150, 140, 120],
              [large.maxPx]: [80, 120, 140, 130, 120],
              [xlarge.maxPx]: [100, 180, 200, 180, 160],
              [xxlarge.maxPx]: [180, 240, 250, 245, 200]
            }
          ],
          scale: [
            this.constructor.inMap(300),
            {
              [small.maxPx]: [0.9, 0.9, 1]
            }
          ]
        },
        man: {
          translateX: [
            this.constructor.inOutMap(250),
            {
              [small.maxPx]: [0, -60, -70, -60, -50],
              [medium.maxPx]: [-40, -150, -170, -160, -150],
              [large.maxPx]: [0, -70, -80, -75, -60],
              [xlarge.maxPx]: [-65, -160, -150, -130, -120],
              [xxlarge.maxPx]: [-90, -125, -140, -130, -100]
            }
          ],
          scale: [
            this.constructor.inMap(300),
            {
              [xxlarge.maxPx]: [1, 1, 0.95]
            }
          ]
        },
        money: {
          translateY: [
            this.constructor.inOutMap(250),
            [-150, -100, -60, 0, -10]
          ],
          translateX: [
            this.constructor.inOutMap(250),
            {
              [small.maxPx]: [0, 20, 30, 25, 10],
              [medium.maxPx]: [10, 43, 50, 45, 35],
              [large.maxPx]: [80, 120, 140, 130, 120],
              [xlarge.maxPx]: [100, 180, 200, 180, 160],
              [xxlarge.maxPx]: [140, 180, 200, 190, 170]
            }
          ],
          scale: [
            this.constructor.inOutMap(300),
            {
              [xlarge.maxPx]: [0.7, 1, 1.2, 1, 1.2]
            }
          ]
        }
      }
    }
  }

  static inMap (hold = 200) {
    return ['elInY-200', `elCenterY-${hold}`, 'elCenterY']
  }

  static inOutMap (hold = 200) {
    return ['elInY-200', `elCenterY-${hold}`, 'elCenterY', `elCenterY+${hold}`, 'elOutY']
  }
}
