import ApplicationController from './application-controller'
import { useIntersection } from 'stimulus-use'
import MediaQuery from 'lib/media-query'

export default class GlideSliderController extends ApplicationController {
  static values = {
    settings: Object,
    selector: String
  }

  static targets = ['track']

  connect () {
    super.connect()
    this.slider = null
    useIntersection(this)
    this.takeSettings()
    this.mediaChange = this.mediaChange.bind(this)
  }

  appear () {
    this.onScreen = true
    if (this.mountOnScreens) {
      MediaQuery.addListener('change', this.mediaChange)
    } else {
      this.mountSlider()
    }
  }

  mediaChange (current) {
    // const { exactly, smallerThan, largerThan } = this.mountOnScreens
    const { smallerThan } = this.mountOnScreens

    smallerThan && (current.unfits(smallerThan) ? this.mountSlider() : this.destroySlider())
  }

  disappear () {
    this.onScreen = false
    MediaQuery.removeListener('change', this.mediaChange)
    this.destroySlider()
  }

  takeSettings () {
    this.mountOnScreens = this.settingsValue.mountOnScreens
    delete this.settingsValue.mountOnScreens
  }

  async mountSlider () {
    if (this.slider) return

    const { GLIDE_SETTINGS, Lazy, Images, Swipe, Breakpoints, Autoplay, Controls, default: Glide } = await import(
      /* webpackChunkName: "glide-bundle" */ 'lib/glide/bundle'
    )

    const settings =
      Object.assign(GLIDE_SETTINGS, Object.assign(this.constructor.defaultSettings, this.settingsValue || {}))

    this.slider =
      new Glide(this.selectorValue, settings)
        .mount({ Images, Swipe, Lazy, Breakpoints, Autoplay, Controls })
    this.nothing = null
  }

  static defaultSettings = {
    type: 'carousel',
    focusAt: 'center',
    startAt: 2,
    gap: 0,
    perView: 4,
    breakpoints: {
      480: { perView: 1 },
      768: { perView: 2 },
      1024: { perView: 3 }
    },
    hoverpause: true,
    autoplay: 4000,
    animationDuration: 400,
    animationTimingFunc: 'linear',
    lazyGlide: { selector: '.lazy' }
  }

  destroySlider () {
    if (!this.slider) return

    this.slider.destroy()
    this.slider = null
  }
}
